import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { AuthService } from '@fcr/azure-auth';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{
  @Input() isLoginPage: boolean = false;
  
  signOutButtonEnabled: boolean = false;

  constructor(public translate: TranslateService, public auth: AuthService, private router: Router, private route: ActivatedRoute){}

  ngOnInit(): void {
    //console.log(this)
    this.getPageType()
  }

  signOut() {
    //console.log("sign out")
    this.auth.signOut()
    this.router.navigateByUrl("/login")
    
  }

  private getPageType(): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(data => {
      //console.log("route data", data)
      let buttonData = data['loginButton'] || 'enabled';
      this.signOutButtonEnabled = buttonData == "enabled"
    });
  }
}
